@media only screen and (max-width: 768px) {
    .divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6vh;
        width: 80%;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6vh;
        width: 80%;
    }

    .product-overview {
        margin-left: 8vw;
        margin-right: 8vw;
    }

}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6vh;
        width: 80%;
    }

    .product-overview {
        margin-left: 8vw;
        margin-right: 8vw;
    }

}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
    .yummly-nav-sections {
        margin-bottom: 33vh;
        margin-top: 19.5vh; 
    }
    .divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6vh;
        width: 50%;
    }

    .product-overview {
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6vh;
    }

    .product-overview {
        margin-left: 18vw;
        margin-right: 18vw;
    }


    .row {
        display: flex;
    }

    .col {
        flex: 50%;
    }

    .outcome {
        padding-bottom: 26vh;
    }

    .challenge {
        padding-left: 6vw;
        padding-right: 6vw;
    }

    .col-image-wrap {
        margin-top: 4.5vh;
        margin-left: 3vw;
    }
    .image-wrap {
        padding-right: 4vw;
        padding-left: 4vw;
    }
}

@media only screen and (min-width: 1501px) {
    .yummly-nav-sections {
        margin-bottom: 33vh;
        margin-top: 19.5vh; 
    }

    .divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6vh;
    }

    .product-overview {
        margin-left: 18vw;
        margin-right: 18vw;
    }


    .row {
        display: flex;
    }

    .col {
        flex: 50%;
    }

    .outcome {
        padding-bottom: 26vh;
    }

    .challenge {
        padding-left: 6vw;
        padding-right: 6vw;
    }
    
    .video {
        width: 80%;
    }
    
    .col-image-wrap {
        margin-top: 4.5vh;
        margin-left: 3vw;
    }
    .image-wrap {
        padding-right: 4vw;
        padding-left: 4vw;
    }
    
}

@media only screen and (min-width: 1800px) {
    .yummly-nav-sections {
        margin-bottom: 37vh;
        margin-top: 24vh; 
    }

    .divider {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 6vh;
    }

    .product-overview {
        margin-left: 18vw;
        margin-right: 18vw;
    }

    .row {
        display: flex;
    }

    .col {
        flex: 50%;
    }

    .outcome {
        padding-bottom: 26vh;
    }

    .video {
        width: 80%;
    }
    .col-image-wrap {
        margin-top: 4.5vh;
        margin-left: 3vw;
    }
    
    
}








