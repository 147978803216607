@media only screen and (max-width: 768px) {

}
@media only screen and (min-width: 769px) and (max-width: 1024px) {


    .product-overview {
        margin-left: 8vw;
        margin-right: 8vw;
    }

}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {


    .product-overview {
        margin-left: 8vw;
        margin-right: 8vw;
    }

}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
    .yirental-nav-sections {
        margin-bottom: 33.5vh;
        margin-top: 19.5vh; 
    }


    .product-overview {
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .project-2 {
        padding-bottom: 26vh;
    }

    .challenge {
        padding-left: 6vw;
        padding-right: 6vw;
    }

    .col-image-wrap {
        margin-top: 4.5vh;
        margin-left: 3vw;
    }

}

@media only screen and (min-width: 1501px) {
    .yirental-nav-sections {
        margin-bottom: 33.5vh;
        margin-top: 19.5vh; 
    }

    .product-overview {
        margin-left: 18vw;
        margin-right: 18vw;
    }


    .project-2 {
        padding-bottom: 26vh;
    }

    .challenge {
        padding-left: 6vw;
        padding-right: 6vw;
    }
    
    .video {
        width: 80%;
    }
    
    .col-image-wrap {
        margin-top: 4.5vh;
        margin-left: 3vw;
    }

    .intern-reflection {
        margin-bottom: 70vh
    }

    .project-1 {
        margin-bottom: 10vh;
    }
    
}

@media only screen and (min-width: 1800px) {
    .yirental-nav-sections {
        margin-bottom: 43vh;
        margin-top: 18vh;  
    }


    .product-overview {
        margin-left: 18vw;
        margin-right: 18vw;
    }

    .project-2 {
        padding-bottom: 26vh;
    }

    .video {
        width: 80%;
    }
    .col-image-wrap {
        margin-top: 4.5vh;
        margin-left: 3vw;
    }

    .intern-reflection {
        margin-bottom: 70vh
    }

    .project-1 {
        margin-bottom: 10vh;
    }
    
    
}



