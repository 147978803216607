html {
  scroll-behavior: smooth;
}

/*https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/*/
/* Mobile + tablet */
@media only screen and (max-width: 769px) {

  .footer-wrap {
    justify-content: left;
    font-size: 1rem;
  }

  .hyperlink-text {
    color: #242424;
  }

  .nav-bar {
    display: none;
  }

  .more-projects-sidenav {
    display: none;
  }

  .project-navbar {
    display: none;
  }

  .App {
    text-align: left;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  * {
    box-sizing: border-box;
    list-style: none;
    text-decoration:none;
    font-size: 16px;
  }

  .mobile-nav {
    background: white;
    padding-bottom: 0.6vh;
    position: fixed;
    top: 0;
    width: 100vw;
    overflow: hidden;
    z-index: 1;
  }

  .mobile-nav ul  {
    padding: 0;
    margin-right: 20vw;  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  li {
    display: inline;
    font-weight: 600;
  }
  
  p {
    font-size: 0.95rem;
    color: #242424;
  }

  .content-wrap {
    margin-top: 20vh;
  }

  .intro-text {
    margin-bottom: 16vh;
  }
  
  .intro-title {
    font-weight: 700;
    font-size: 1.2rem;
    color: #242424;
  }
  
  .intro-subtitle {
    font-weight: 400;
    font-size: 1.05rem;
    color: #242424;
  }
 
  
  .intro-description {
    font-weight: 700;
    font-size: .9rem;
    color: #242424;
  }
  
  .mobile-socials {
    margin-top: 3vh;
    color: #242424;
    margin-bottom: 12vh;
  }

  .cover-page img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 
    
  .more-projects {
    margin-top: 12vh;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .cover-page {
    width: 80vw;
    height: 57.8vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }

  .projects-grid_2 {
    margin-top: 4vh;
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    margin-bottom: 10vh;
  }
  
  .cover-page_2 img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page_2 {
    width: 80vw; 
    height: 57.8vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }
    
  .cover-page_2:hover img { 
    transform: scale(1.3); 
  } 

  .project-title {
    margin-top: 2vh;
    font-size: .9rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle {
    margin-top: 0.8vh;
    color: #242424;
    margin-bottom: 10vh;
    font-size: 0.8rem;
  }

  
  .project-title2 {
    margin-top: 2vh;
    font-size: 0.9rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle2 {
      margin-top: 0.8vh;
      color: #242424;
      margin-bottom: 10vh;
      font-size: 0.8rem;
  } 


  .mobile-credits {
    font-weight: 400;
    font-size: 0.68rem;
    color: #242424;
    margin-bottom: 4vh;
  }

  /* project-related stuff */
  .project-navbar {
    position: fixed;
    margin-top: 6vh;
  }

  .active {
    font-weight: 700; 
    color: #242424;
    background-size: 100%;
  }

  .projectpage-title {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 2vh;
  }

  .projectpage-subtitle {
    font-size: 1.4rem;
    color: #242424;
  }

  .back-button {
    margin-top: 6vh; 
  }

  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 0.95rem;
  }

  .extra-large-text {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }

  .extra-large-text-buddy {
    font-size: 1.2rem; 
    text-align: center; 
    padding-bottom: 2vh;
    color: #242424;
  } 
 
  .emphasis-text {
    font-size: 1.1rem;
    font-weight: 700;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-bottom: 5vh;
  }

  .emphasis-text-buddy{
    font-size: 0.9rem;  
    padding-bottom: 1vh;
    color: #242424;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-top: 5vh;
  }
  
  .pic-caption {
    font-size: 0.8rem;
    margin-top: 1vh;
    font-weight: 300;
    color: #242424;
  }

  .divider {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6vh;
    width: 80%;
}
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width: 1024px) {

  .footer-wrap {
    justify-content: left;
    font-size: 1rem;
  }

  .hyperlink-text {
    color: #242424;
  }

  .center-wrap {
    margin-left: 4vw;
    margin-right: 4vw;
  }

  .nav-bar {
    display: none;
  }

  .more-projects-sidenav {
    display: none;
  }

  .project-navbar {
    display: none;
  }

  .App {
    text-align: left;
    padding-left: 14vw;
    padding-right: 14vw;
  }

  * {
    box-sizing: border-box;
    list-style: none;
    text-decoration:none;
    font-size: 14px;
  }

  .mobile-nav {
    background: white;
    padding-bottom: 0.6vh;
    position: fixed;
    top: 0;
    width: 100vw;
    overflow: hidden;
    z-index: 1;
  }

  .mobile-nav ul  {
  padding: 0;
  margin-right: 28vw;  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  }

  li {
    display: inline;
    font-weight: 600;
  }

  p {
    font-size: 0.9rem;
    color: #242424;
  }

  .content-wrap {
    margin-top: 26vh;
  }

  .intro-text {
    margin-bottom: 22vh;
  }
  
  .intro-title {
    font-weight: 700;
    font-size: 1.4rem;
    color: #242424;
  }
  
  .intro-subtitle {
    font-weight: 400;
    font-size: 1.2rem;
    color: #242424;
  }


  
  
  .intro-description {
    font-weight: 700;
    font-size: .9rem;
    color: #242424;
  }
  
  .mobile-socials {
    margin-top: 7vh;
    color: #242424;
    margin-bottom: 14vh;
  }

  .cover-page img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 
    
  .more-projects {
    margin-top: 16vh;
    font-weight: 700;
    font-size: 1.3rem;
  }

  .cover-page {
    width: 72vw;
    height: 52vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }

  .projects-grid_2 {
    margin-top: 8vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 14vh;
  }
  
  .cover-page_2 img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page_2 {
    width: 72vw; 
    height: 52vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }
    
  .cover-page_2:hover img { 
    transform: scale(1.3); 
  } 

  .project-title {
    margin-top: 3.2vh;
    font-size: 1.1rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle {
    margin-top: 1.6vh;
    color: #242424;
    margin-bottom: 7vh;
    font-size: 1rem;
  }

  
  .project-title2 {
    margin-top: 2.2vh;
    font-size: 1.1rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle2 {
    font-weight: 400;
    margin-top: 0.4vh;
    font-size: 1rem;
    color: #242424;
    margin-bottom: 7vh;
  } 

  .mobile-credits {
    font-weight: 400;
    font-size: 0.9rem;
    color: #242424;
    margin-bottom: 6vh;
  }

    /* project-related stuff */
    .project-navbar {
      position: fixed;
      margin-top: 6vh;
    }
  
    .active {
      font-weight: 700; 
      color: #242424;
      background-size: 100%;
    }
  
    .projectpage-title {
      font-size: 2rem;
      font-weight: 700;
    }
  
    .projectpage-type {
      font-size: 1.3rem;
      color: #242424;
    }
  
    .back-button {
      margin-top: 6vh; 
    }
  
    h1 {
      font-size: 1.2rem;
    }
  
    h2 {
      font-size: 1rem;
    }
  
    h3 {
      font-size: 0.9rem;
    }

    .extra-large-text {
      font-size: 1.4rem;
      font-weight: 700;
      text-align: center;
    }
  
    .extra-large-text-buddy {
      font-size: 1.1rem; 
      text-align: center; 
      padding-bottom: 2vh;
      color: #242424;
    } 

    .emphasis-text {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 8vh;
      padding-left: 4vw;
      padding-right: 4vw
    }

    .emphasis-text-buddy{
      font-size: 0.9rem;  
      padding-bottom: 1vh;
      color: #242424;
      padding-left: 4vw;
      padding-right: 4vw;
      margin-top: 8vh;
    }


    .pic-caption {
      font-size: 0.8rem;
      margin-top: 1vh;
      font-weight: 300;
      color: #242424;
    }

    .divider {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 6vh;
      width: 80%;
  }
}

/* Desktop + large screens */ /*horizontal nav/fix project grid*/
@media only screen and (min-width: 1025px) and (max-width: 1200px) {

  .footer-wrap {
    justify-content: space-between;
    font-size: 1.4rem;
  }

  .hyperlink-text {
    color: #242424;
  }

  .center-wrap {
    margin-left: 6vw;
    margin-right: 6vw;
  }
   
  
  .nav-bar {
    display: none;
  }

  .more-projects-sidenav {
    display: none;
  }

  .project-navbar {
    display: none;
  }

  .App {
    text-align: left;
    padding-left: 12vw;
    padding-right: 12vw;
  }

  * {
    box-sizing: border-box;
    list-style: none;
    text-decoration:none;
    font-size: 14px;
  }

  .mobile-nav {
    background: white;
    padding-bottom: 0.6vh;
    position: fixed;
    top: 0;
    width: 100vw;
    overflow: hidden;
    z-index: 1;
  }

  .mobile-nav ul  {
  padding: 0;
  margin-right: 24vw;  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  }

  li {
    display: inline;
    font-weight: 600;
  }

  p {
    font-size: 1.3rem;
    color: #242424;
  }

  .content-wrap {
    margin-top: 18vh;
  }

  .intro-title {
    font-weight: 700;
    font-size: 1.4rem;
    color: #242424;
  }
  
  .intro-subtitle {
    font-weight: 400;
    font-size: 1.2rem;
    color: #242424;
  }

  
  .intro-description {
    font-weight: 700;
    font-size: 1.05rem;
    color: #242424;
  }

  .mobile-socials {
    margin-top: 6vh;
    color: #242424;
  }
  
  .projects-grid {
    margin-top: 14vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  .cover-page {
    width: 24vw; 
    height: 17.3vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }

  .cover-page img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page:hover img { 
    transform: scale(1.3); 
  } 

  .more-projects {
    margin-top: 14vh;
    font-weight: 700;
    font-size: 1.3rem;
    color: #242424;
  }

  .projects-grid_2 {
    margin-top: 6vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 14vh;
  }

  .cover-page_2 {
    width: 17.64vw; 
    height: 12.74vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }
  
  .cover-page_2 img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page_2:hover img { 
    transform: scale(1.2); 
  } 

  .project-title {
    margin-top: 2vh;
    font-size: 1rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle {
    margin-top: 0.3vh;
    font-size: 0.9rem;
    color: #242424;
  } 

  
  .project-title2 {
    margin-top: 2.2vh;
    font-size: 0.9rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle2 {
    font-weight: 400;
    margin-top: 0.4vh;
    font-size: 0.8rem;
    color: #242424;
  } 

  .mobile-credits {
    font-size: 0.7rem;
    margin-bottom: 3vh;
  }

    /* project-related stuff */
    .project-navbar {
      position: fixed;
      margin-top: 6vh;
    }

    .active {
      font-weight: 700; 
      color: #242424;
      background-size: 100%;
    }
  
    .projectpage-title {
      font-size: 2rem;
      font-weight: 700;
    }
  
    .projectpage-type {
      font-size: 1.5rem;
      color: #242424;
      padding-bottom: "6vh";
    }
  
    h1 {
      font-size: 1.6rem;
    }
  
    h2 {
      font-size: 1.4rem;
    }

    h3 {
      font-size: 1.3rem;
    }
  
  
    .extra-large-text {
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
    }
  
    .extra-large-text-buddy {
      font-size: 1.5rem; 
      text-align: center; 
      padding-bottom: 2vh;
      color: #242424;
    } 

    .emphasis-text {
      font-size: 1.4rem;
      font-weight: 700;
      padding-left: 6vw;
      padding-right: 6vw;
      margin-bottom: 10vh;
    }

    .emphasis-text-buddy{
      font-size: 1.2rem;  
      padding-bottom: 1vh;
      color: #242424;
      padding-left: 6vw;
      padding-right: 6vw;
      margin-top: 10vh;
    }

    .pic-caption {
      font-size: 1.2rem;
      font-weight: 300;
      margin-top: 2vh;
      color: #242424;
    }

    .row {
      display: flex;
    }

    .col {
        flex: 50%;
    }

    .divider {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 6vh;
      width: 50%;
  }

}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%; max-width: 212px;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}

  .footer-wrap {
    justify-content: space-between;
    font-size: 1.4rem;
  }

  .hyperlink-text {
    color: #242424;
  }

  .center-wrap {
   margin-left: 10vw;
   margin-right: 10vw;
 }
  
 .mobile-nav {
   display: none;
 }

 .more-projects {
   display: none;
 }

 .mobile-socials {
   display: none;
 }

 .mobile-credits {
   display: none;
 }

 .row::after {
   content: "";
   clear: both;
   display: table;
 }
 
 [class*="col-"] {
   float: left;
   padding: 20px;
   /*border: 1px solid red;  */
 }

 * {
   box-sizing: border-box;
   list-style: none;
   text-decoration:none;
   font-size: 12px;
 }

 .App {
   text-align: left;
   padding-left: 8vw;
   padding-right: 8vw;
   /*10*/
 }

 .col-10 {
   border-left: 2px solid #F0F0F0;
 }

 .nav-bar {
   position: fixed;
 }

 .content-wrap {
   margin-left: 2vw;
   /*0.6*/
   margin-top: 4.75vh;
 }

 .site-title{
   margin-top: 4vh;
   /*5vh*/
   font-weight: 700;
   font-size: 1.22rem;
   color: #242424;
 }
 
 .site-subtitle{
   font-weight: 400;
   font-size: 1rem;
   color: #242424;
 }

 li {
   font-weight: 600;
   font-size: 1rem;
   margin-bottom: 1vh;
 }

 ul {
   padding: 0;
   margin-top: 7.5vh;
   margin-bottom: 44vh;
   /*margin-bottom: 41vh;*/
 }

 p {
   font-size: 1.3rem;
   color: #242424;
   margin: 0;
 }

 .credits {
   font-size: 0.85rem;
   font-weight: 400;
   color: #242424;
 }

 .intro-title {
   font-weight: 700;
   font-size: 1.6rem;
   color: #242424;
 }
 
 .intro-subtitle {
   font-weight: 400;
   font-size: 1.4rem;
   color: #242424;
 }

 .intro-description {
   font-weight: 700;
   font-size: 1.34rem;
   color: #242424;
 }

 .projects-grid {
   margin-top: 9.2vh;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
 }

 .cover-page {
   width: 20vw; 
   height: 14.4vw; 
   overflow: hidden; 
   margin: 0 auto; 
 }

 .cover-page img { 
   width: 100%; 
   height: 100%;
   transition: 0.5s all ease-in-out; 
 } 

 .cover-page:hover img { 
   transform: scale(1.3); 
 } 

 .more-projects-sidenav {
   margin-top: 20vh;
   /*19.6vh*/
 }

 .projects-grid_2 {
   margin-top: 9.2vh;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   margin-bottom: 48vh;
 }

 .cover-page_2 {
   width: 14.7vw; 
   height: 10.6vw;
   overflow: hidden; 
   margin: 0 auto; 
 }
 
 .cover-page_2 img { 
   width: 100%; 
   height: 100%;
   transition: 0.5s all ease-in-out; 
 } 

 .cover-page_2:hover img { 
   transform: scale(1.2); 
 } 

 .project-title {
   margin-top: 2.2vh;
   font-size: 1.25rem;
   font-weight: 700;
   color: #242424;
 }

 .project-subtitle {
   font-weight: 400;
   margin-top: 0.4vh;
   font-size: 1.1rem;
   color: #242424;
 } 

 .project-title2 {
   margin-top: 2.2vh;
   font-size: 1.05rem;
   font-weight: 700;
   color: #242424;
 }

 .project-subtitle2 {
   font-weight: 400;
   margin-top: 0.4vh;
   font-size: 1rem;
   color: #242424;
 } 

 /*project page related */
 .project-navbar {
   position: fixed;
   margin-top: 6vh;
 }

 .active {
   font-weight: 700; 
   color: #242424;
   background-size: 100%;
 }

 .projectpage-title {
   font-size: 2.2rem;
   font-weight: 700;
   margin-bottom: 1vh;
 }

 .projectpage-type{
   font-size: 1.7rem;
   color: #242424;
   font-weight: 400;
   padding-bottom: 5.5vh;
 }

 .projectpage-subtitle {
   margin-top: 2vh;
   font-size: 2rem;
   color: #242424;
   font-weight: 600;
 }

 .back-button {
   margin-top: 6vh; 
 }

 h1 {
   font-size: 1.8rem;
 }

 h2 {
   font-size: 1.4rem;
 }

 h3 {
   font-size: 1.3rem;
 }

 .extra-large-text {
   font-size: 2rem;
   font-weight: 700;
   text-align: center;
 }

 .extra-large-text-buddy {
   font-size: 1.6rem; 
   text-align: center; 
   padding-bottom: 2vh;
   color: #242424;
 } 

 .emphasis-text {
   font-size: 1.7rem;
   font-weight: 700;
   padding-left: 6vw;
   padding-right: 6vw;
   margin-bottom: 10vh;
 }

 .emphasis-text-buddy{
   font-size: 1.4rem;  
   padding-bottom: 2vh;
   color: #242424;
   padding-left: 6vw;
   padding-right: 6vw;
   margin-top: 10vh;
 }

 .pic-caption {
   font-size: 1.1rem;
   font-weight: 300;
   margin-top: 2vh;
   color: #242424;
 }

 .row {
   display: flex;
 }

 .col {
     flex: 50%;
 }

 .divider {
   display: block;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 6vh;
}
}

/* Extra large screens + desktop*/ /*Navbar height adjustments*/
@media only screen and (min-width: 1501px) and (max-width: 1920px) {
   /* For desktop: */
   .col-1 {width: 8.33%;}
   .col-2 {width: 16.66%; max-width: 212px;}
   .col-3 {width: 25%;}
   .col-4 {width: 33.33%;}
   .col-5 {width: 41.66%;}
   .col-6 {width: 50%;}
   .col-7 {width: 58.33%;}
   .col-8 {width: 66.66%;}
   .col-9 {width: 75%;}
   .col-10 {width: 83.33%;}
   .col-11 {width: 91.66%;}
   .col-12 {width: 100%;}

   .footer-wrap {
    justify-content: space-between;
    font-size: 1.4rem;
  }

   .hyperlink-text {
    color: #242424;
  }

   .center-wrap {
    margin-left: 10vw;
    margin-right: 10vw;
  }
   
  .mobile-nav {
    display: none;
  }

  .more-projects {
    display: none;
  }

  .mobile-socials {
    display: none;
  }

  .mobile-credits {
    display: none;
  }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }
  
  [class*="col-"] {
    float: left;
    padding: 20px;
    /*border: 1px solid red;  */
  }

  * {
    box-sizing: border-box;
    list-style: none;
    text-decoration:none;
    font-size: 12px;
  }

  .App {
    text-align: left;
    padding-left: 8vw;
    padding-right: 8vw;
    /*10*/
  }

  .col-10 {
    border-left: 2px solid #F0F0F0;
  }

  .nav-bar {
    position: fixed;
  }

  .content-wrap {
    margin-left: 2vw;
    /*0.6*/
    margin-top: 4.75vh;
  }


  .site-title{
    margin-top: 4vh;
    /*5vh*/
    font-weight: 700;
    font-size: 1.22rem;
    color: #242424;
  }
  
  .site-subtitle{
    font-weight: 400;
    font-size: 1rem;
    color: #242424;
  }

  li {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1vh;
  }

  ul {
    padding: 0;
    margin-top: 7.5vh;
    margin-bottom: 44vh;
    /*margin-bottom: 41vh;*/
    
  }

  p {
    font-size: 1.3rem;
    color: #242424;
    margin: 0;
  }

  .credits {
    font-size: 0.85rem;
    font-weight: 400;
    color: #242424;
  }

  .intro-title {
    font-weight: 700;
    font-size: 1.6rem;
    color: #242424;
  }
  
  .intro-subtitle {
    font-weight: 400;
    font-size: 1.4rem;
    color: #242424;
  }

  .intro-description {
    font-weight: 700;
    font-size: 1.34rem;
    color: #242424;
  }

  .projects-grid {
    margin-top: 9.2vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cover-page {
    width: 20vw; 
    height: 14.4vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }

  .cover-page img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page:hover img { 
    transform: scale(1.3); 
  } 

  .more-projects-sidenav {
    margin-top: 20vh;
    /*19.6vh*/
  }

  .projects-grid_2 {
    margin-top: 9.2vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 48vh;
  }

  .cover-page_2 {
    width: 14.7vw; 
    height: 10.6vw;
    overflow: hidden; 
    margin: 0 auto; 
  }
  
  .cover-page_2 img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page_2:hover img { 
    transform: scale(1.2); 
  } 

  .project-title {
    margin-top: 2.2vh;
    font-size: 1.25rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle {
    font-weight: 400;
    margin-top: 0.4vh;
    font-size: 1.1rem;
    color: #242424;
  } 

  .project-title2 {
    margin-top: 2.2vh;
    font-size: 1.1rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle2 {
    font-weight: 400;
    margin-top: 0.4vh;
    font-size: 1rem;
    color: #242424;
  } 

  /*project page related */
  .project-navbar {
    position: fixed;
    margin-top: 6vh;
  }

  .active {
    font-weight: 700; 
    color: #242424;
    background-size: 100%;
  }

  .projectpage-title {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1vh;
  }

  .projectpage-type{
    font-size: 1.7rem;
    color: #242424;
    font-weight: 400;
    padding-bottom: 5.5vh;
  }

  .projectpage-subtitle {
    margin-top: 2vh;
    font-size: 2rem;
    color: #242424;
    font-weight: 600;
  }

  .back-button {
    margin-top: 6vh; 
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  .extra-large-text {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .extra-large-text-buddy {
    font-size: 1.6rem; 
    text-align: center; 
    padding-bottom: 2vh;
    color: #242424;
  } 

  .emphasis-text {
    font-size: 1.7rem;
    font-weight: 700;
    padding-left: 6vw;
    padding-right: 6vw;
    margin-bottom: 10vh;
  }

  .emphasis-text-buddy{
    font-size: 1.4rem;  
    padding-bottom: 2vh;
    color: #242424;
    padding-left: 6vw;
    padding-right: 6vw;
    margin-top: 10vh;
  }

  .pic-caption {
    font-size: 1.1rem;
    font-weight: 300;
    margin-top: 2vh;
    color: #242424;
  }

  .row {
    display: flex;
  }

  .col {
      flex: 50%;
  }

  .divider {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6vh;
}
}

/* Extra extra large screens + desktop*/ /*Navbar height adjustments*/
@media only screen and (min-width: 1921px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%; max-width: 260px}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}

  .footer-wrap {
    justify-content: space-between;
    font-size: 1.4rem;
  }

  .hyperlink-text-text {
    color: #242424;
  }

  .center-wrap {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .mobile-nav {
    display: none;
  }

  .more-projects {
  display: none;
  }

  .mobile-socials {
    display: none;
  }

  .mobile-credits {
    display: none;
  }


  .row::after {
    content: "";
    clear: both;
    display: table;
  }

  [class*="col-"] {
    float: left;
    padding: 20px;
    /* border: 1px solid red;  */
  }

  * {
    box-sizing: border-box;
    list-style: none;
    text-decoration:none;
    font-size: 16px;
  }

  .App {
    text-align: left;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 60vw;
  }

  .col-10 {
    border-left: 2px solid #F0F0F0;
  }

  .nav-bar {
    position: fixed;
  }

  .content-wrap {
    margin-left: 2vw;
    margin-top: 4.75vh;
  }

  .site-title{
    margin-top: 4vh;
    font-weight: 700;
    font-size: 1.3rem;
    color: #242424;
  }

  .site-subtitle{
    font-weight: 400;
    font-size: 1.1rem;
    color: #242424;
  }

  li {
    font-weight: 600;
    font-size: 1.05rem;
    margin-bottom: 0.7vh;
  }

  ul {
    padding: 0;
    margin-top: 20vh;
    /*margin-bottom: 41vh;*/
    margin-bottom: 44vh;
  }

  p {
    font-size: 1.3rem;
    color: #242424;
  }

  .credits {
    font-size: 0.85rem;
    color: #242424;
    font-weight: 400;
  }

  .intro-title {
    font-weight: 700;
    font-size: 1.6rem;
    color: #242424;
  }

  .intro-subtitle {
    font-weight: 400;
    font-size: 1.3rem;
    color: #242424;
  }

  .intro-description {
    font-weight: 700;
    font-size: 1.34rem;
    color: #242424;
  }

  .projects-grid {
    margin-top: 10.6vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cover-page {
    width: 20vw; 
    height: 14.4vw; 
    overflow: hidden; 
    margin: 0 auto; 
  }

  .cover-page img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page:hover img { 
    transform: scale(1.3); 
  } 

  .more-projects-sidenav {
    margin-top: 25.5vh;
  }

  .projects-grid_2 {
    margin-top: 10.6vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 51vh;
  }

  .cover-page_2 {
    width: 14.7vw; 
    height: 10.6vw;
    overflow: hidden; 
    margin: 0 auto; 
  }

  .cover-page_2 img { 
    width: 100%; 
    height: 100%;
    transition: 0.5s all ease-in-out; 
  } 

  .cover-page_2:hover img { 
    transform: scale(1.2); 
  } 

  .project-title {
    margin-top: 2.2vh;
    font-size: 1.4rem;
    font-weight: 700;
    color: #242424;
  }

  .project-subtitle {
    margin-top: 0.4vh;
    font-size: 1.2rem;
    color: #242424;
  } 

  .project-title2 {
  margin-top: 2.2vh;
  font-size: 1.1rem;
  font-weight: 700;
  color: #242424;
  }

  .project-subtitle2 {
  font-weight: 400;
  margin-top: 0.4vh;
  font-size: 1rem;
  color: #242424;
  } 

  /*project page related */
  .project-navbar {
    position: fixed;
    margin-top: 6vh;
  }

  .active {
    font-weight: 700; 
    color: #242424;
    background-size: 100%;
  }

  .projectpage-title {
    font-size: 2.2rem;
    font-weight: 700;
  }

  .projectpage-type {
    font-size: 1.5rem;
    color: #242424;
    padding-bottom: 5.5vh;
  }

  .back-button {
    margin-top: 6vh; 
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  .extra-large-text {
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
  }

  .extra-large-text-buddy {
    font-size: 1.6rem; 
    text-align: center; 
    padding-bottom: 2vh;
    color: #242424;
  } 
  .emphasis-text {
    font-size: 1.7rem;
    font-weight: 700;
    padding-left: 6vw;
    padding-right: 6vw;
    margin-bottom: 10vh;
  }

  .emphasis-text-buddy{
    font-size: 1.4rem;  
    padding-bottom: 2vh;
    color: #242424;
    padding-left: 6vw;
    padding-right: 6vw;
    margin-top: 10vh;
  }

  .pic-caption {
    font-size: 1.1rem;
    font-weight: 300;
    margin-top: 2vh;
    color: #242424;
  }

  .row {
    display: flex;
  }

  .col {
      flex: 50%;
  }

  .divider {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6vh;
}

}


/* Universal/1201px+ only */

.footer-wrap {
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
}
li:hover {
  font-weight:700;
}

a {
  color: #747474;
}

a:hover {
  color:#242424;
  
}

a, a:link {
  background-position-y: -0%;
  background-image: linear-gradient( white 80%, #FFB35C 20%);
  transition: 0.6s ease;
  background-size: auto 175%;
}


a:hover {
  background-position-y: 100%;
}


.cover-page:hover img { 
  transform: scale(1.3); 
}

.border {
  height: 80rem;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2.1vh;
  color: #242424;
}

#email-icon {
  margin-right: 2vh;
}

h1 {
  font-weight: 700;
  padding-top: 8vh;
  margin-bottom: 4vh;
}


h2 {
  font-weight: 700;
  margin-bottom: 1vh;
}

h3 {
  font-weight: 600;
  margin-bottom: 1vh;
}





/*
.projects-grid {
  margin-top: 7.5vh;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 1.1rem;
}
*/



.button {
  margin-top: 4%;
  background-color: white;
  border: 2px solid #242424;
  font-weight: 500;
  padding: 10px 30px 10px 30px;
  font-family : inherit;
  margin-bottom: 100%;
}

/*different colour depending on project*/ 
.button:hover {
  background: #242424;
  font-weight: 500;
  color: white;
}

/*text-highlight colour*/
::-moz-selection {
  background: #b6b6b6;
}
::-webkit-selection {
  background: #b6b6b6;
}
::selection {
  background:#b6b6b6;
}


/*
@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
.animated-title {
  height: 27.5vmin;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 27.5vmin;
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.animated-title > div div {
  font-size: 3vmin;
  padding: 0.5vmin 0;
  position: absolute;
}
.animated-title > div div span {
  display: block;
}
.animated-title > div.text-top {
  border-bottom: 0.4vmin solid #FFB35C;
  top: 0;
}
.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
  color: #747474;
}
.animated-title > div.text-bottom {
  bottom: 0;
}
.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}
*/